/* eslint-disable react/require-default-props */
import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';
import Cookies from 'js-cookie';

interface BannerProps {
  shortText: string,
  text: string,
  cookieName: string,
  isVisible?: boolean,
  isDismissible?: boolean,
  linkElement?:JSX.Element,
}

export default function Banner({
  shortText, text, cookieName, isVisible = true, isDismissible = true, linkElement = undefined,
}: BannerProps) {
  const [isShow, setIsShow] = React.useState(isVisible && Cookies.get(cookieName) !== 'dismissed');
  const dismissBanner = () => {
    setIsShow(false);
    Cookies.set(cookieName, 'dismissed', { expires: 1 });
  };

  return (
    <Transition show={isShow} leave="transition-opacity duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
      <div className="relative bg-gray-700">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden">{shortText}</span>
              <span className="hidden md:inline">{text}</span>
              <span className="block sm:ml-2 sm:inline-block">
                { linkElement && linkElement }
              </span>
            </p>
          </div>
          {isDismissible && (
            <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
              <button
                type="button"
                onClick={() => dismissBanner()}
                className="flex p-2 hover:bg-gray-500 focus:outline-none focus:ring-1 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
}
