/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'gatsby';

export interface SafeLinkProps {
  isExternal: boolean
  children: React.ReactNode
  to: string
  className?: string
}

/**
 * SafeLink helps us support both external
 * and internal links safely with the
 * optimized runtime performance.
 */
export default function SafeLink({
  isExternal, to, children, className = '', ...restProps
}: SafeLinkProps & JSX.IntrinsicAttributes) {
  return (
    <>
      {isExternal ? (
        <a href={to} className={className} target="_blank" rel="noreferrer noopener" {...restProps}>
          {children}
        </a>
      ) : (
        <Link to={to} className={className} {...restProps}>
          {children}
        </Link>
      )}
    </>
  );
}
