import React, { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Icon from '@mdi/react';
import { mdiGithub } from '@mdi/js';
import classNames from 'classnames';
import SafeLink from './SafeLink';

interface NavBarProps {
  githubURL: string;
  logo: string,
  navbarItems: {
    name: string,
    text: string,
    href: string,
    isExternal: boolean,
  }[]
}

/**
 * The navigation bar component.
 */
export default function NavBar(props: NavBarProps) {
  // turn black on scroll
  const [atTop, setAtTop] = useState(true);
  const scrollListener = () => {
    if (window.scrollY >= 10) {
      setAtTop(false);
    } else {
      setAtTop(true);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return (
    <div>
      <Disclosure
        as="nav"
        className={classNames({
          'bg-gray-900': true,
          'shadow-2xl': !atTop,
        })}
      >
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <SafeLink isExternal={false} to="/">
                      <img
                        className="h-10 w-24 ml-0 pb-0.5"
                        src={props.logo}
                        alt="Logo"
                      />
                      {/* eslint-disable-next-line max-len */}
                      {/* <span className="text-gray-300 hover:text-white text-2xl font-bold">Taichi Graphics</span> */}
                    </SafeLink>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-8 mt-0.5 flex items-baseline space-x-4">
                      {props.navbarItems.map((navbarItem) => (
                        <SafeLink
                          key={navbarItem.name}
                          to={navbarItem.href}
                          isExternal={navbarItem.isExternal}
                          className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-base font-medium animated-underline"
                        >
                          {navbarItem.text}
                        </SafeLink>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* Github Link */}
                    <SafeLink
                      isExternal
                      to={props.githubURL}
                      className="text-gray-400 hover:text-gray-300"
                    >
                      <Icon path={mdiGithub} title="Github" size={1.5} />
                    </SafeLink>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {props.navbarItems.map((navbarItem) => (
                  <SafeLink
                    key={navbarItem.name}
                    to={navbarItem.href}
                    isExternal={navbarItem.isExternal}
                    className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    {navbarItem.text}
                  </SafeLink>
                ))}
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Github Link */}
                  <SafeLink
                    isExternal
                    to={props.githubURL}
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <Icon path={mdiGithub} title="Github" size={1} className="text-gray-400" />
                  </SafeLink>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
