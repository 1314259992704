import React from 'react';
import { Helmet } from 'react-helmet';
import { PageProps } from 'gatsby';
import Layout from '../components/Layout';
import BackToTop from '../components/BackToTop';

/**
 * A template page component for displaying policies content.
 */
export default function PolicyTemplate({ pageContext, children }: PageProps) {
  return (
    <Layout>
      <Helmet>
        {/* @ts-expect-error: Seems Gatsby types have an issue here, ignore for now. */}
        <title>{pageContext.frontmatter.title}</title>
        <meta
          // @ts-expect-error: Seems Gatsby types have an issue here, ignore for now.
          name={pageContext.frontmatter.name}
          // @ts-expect-error: Seems Gatsby types have an issue here, ignore for now.
          content={pageContext.frontmatter.title}
        />
      </Helmet>

      <div className="relative py-16 bg-warm-gray-50">
        <div className="relative px-6 sm:px-10 lg:px-2">
          <article className="max-w-prose mx-auto prose prose-md">
            {children}
          </article>
        </div>
      </div>
      <BackToTop showBelow={250} />
    </Layout>
  );
}
