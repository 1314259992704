import React from 'react';
import SafeLink from './SafeLink';

interface FooterProps {
  products: {
    name: string,
    isExternal: boolean,
    href: string,
  }[],
  resources: {
    name: string,
    isExternal: boolean,
    href: string,
  }[],
  company: {
    name: string,
    isExternal: boolean,
    href: string,
  }[],
  legal: {
    name: string,
    isExternal: boolean,
    href: string,
  }[],
  social: {
    name: string,
    isExternal: boolean,
    href: string,
    icon: Function
  }[],
}

/**
 * The footer component.
 */
export default function Footer({
  products, resources, company, legal, social,
}: FooterProps) {
  return (
    <footer className="bg-gray-900" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              {/* Product Section */}
              <div>
                <h3 className="text-l font-semibold text-gray-400 tracking-wider uppercase">
                  Products
                </h3>
                <ul className="mt-4 space-y-4">
                  {products.map((item) => (
                    <li key={item.name}>
                      <SafeLink
                        isExternal={item.isExternal}
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </SafeLink>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Resources Section */}
              <div className="mt-12 md:mt-0">
                <h3 className="text-l font-semibold text-gray-400 tracking-wider uppercase">
                  Resources
                </h3>
                <ul className="mt-4 space-y-4">
                  {resources.map((item) => (
                    <li key={item.name}>
                      <SafeLink
                        isExternal={item.isExternal}
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </SafeLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="md:grid md:grid-cols-2 md:gap-8">
              {/* Company Section */}
              <div>
                <h3 className="text-l font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  {company.map((item) => (
                    <li key={item.name}>
                      <SafeLink
                        isExternal={item.isExternal}
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </SafeLink>
                    </li>
                  ))}
                </ul>
              </div>

              {/* Legal Section */}
              <div className="mt-12 md:mt-0">
                <h3 className="text-l font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  {legal.map((item) => (
                    <li key={item.name}>
                      <SafeLink
                        isExternal={item.isExternal}
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </SafeLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {social.map((item) => (
              <SafeLink
                key={item.name}
                isExternal={item.isExternal}
                to={item.href}
                className="text-gray-400 hover:text-gray-300"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </SafeLink>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            {`Copyright © ${new Date().getFullYear()} Taichi Graphics Technology, Inc. All rights reserved.`}
          </p>
        </div>
      </div>
    </footer>
  );
}
