import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import classNames from 'classnames';

export interface BackToTopProps {
  showBelow?: number
}

/**
 * BackToTop shows a button, when click
 * it will scroll to the page top. Useful
 * for long pages.
 */
export default function BackToTop({ showBelow = 300 }: BackToTopProps) {
  const [show, setShow] = useState(!showBelow);
  const [effect, setEffect] = useState(false);
  const animationProps = useSpring({
    to: { opacity: show ? 1 : 0 },
    // enter: { opacity: 1 },
    // leave: { opacity: 0 },
    from: { opacity: 0 },
    delay: 100,
    // customized with https://react-spring-visualizer.com/#scale
    config: {
      tension: 69,
      friction: 55,
      clamp: true,
      precision: 0.161,
    },
  });
  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) {
        setShow(true);
        setEffect(false);
      }
    } else if (show) setShow(false);
  };

  const handleClick = () => {
    setEffect(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleKeyPress = () => {
    setEffect(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    if (showBelow) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
    return undefined;
  });
  return (
    <animated.div style={animationProps}>
      <div
        role="button"
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        onAnimationEnd={() => setEffect(false)}
        tabIndex={-1}
      >
        {/* TODO: use react-spring to control spin as well */}
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          className={classNames({
            'fixed md:right-4 xs:right-3 bottom-4 z-10 w-10 h-10 fill-current text-cool-gray-400': true,
            'animate-spin': effect,
          })}
        >
          <path d="M13 18v-8l3.5 3.5l1.42-1.42L12 6.16l-5.92 5.92L7.5 13.5L11 10v8h2M12 2a10 10 0 0 1 10 10a10 10 0 0 1-10 10A10 10 0 0 1 2 12A10 10 0 0 1 12 2z" fill="currentColor" />
        </svg>
      </div>
    </animated.div>
  );
}
