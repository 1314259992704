/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import NavBar from './Navbar';
import Footer from './Footer';
import Logo from '../images/branding/white_words.svg';
import SafeLink from './SafeLink';
import Banner from './Banner';

interface LayoutProps {
  children: React.ReactNode,
}

// Navbar data
const navbarItems = [
  {
    name: 'get-started',
    text: 'Get Started',
    href: 'https://docs.taichi.graphics/get-started',
    isExternal: true,
  },
  {
    name: 'docs',
    text: 'Docs',
    href: `${process.env.GATSBY_DOCS_LINK}`,
    isExternal: true,
  },
  {
    name: 'blogs',
    text: 'Blogs',
    href: `${process.env.GATSBY_BLOGS_LINK}`,
    isExternal: true,
  },
  {
    name: 'forum',
    text: 'Forum',
    href: 'https://forum.taichi.graphics/',
    isExternal: true,
  },
];

// Footer data
const products = [
  { name: 'Taichi Programming Language', isExternal: true, href: 'https://github.com/taichi-dev/taichi' },
];
const resources = [
  { name: 'Blogs', isExternal: true, href: `${process.env.GATSBY_BLOGS_LINK}` },
  { name: 'Documentation', isExternal: true, href: `${process.env.GATSBY_DOCS_LINK}` },
  { name: 'Forum', isExternal: true, href: 'https://forum.taichi.graphics' },
];
const company = [
  { name: 'Careers', isExternal: true, href: 'https://app.mokahr.com/apply/taichi' },
  { name: 'Contact', isExternal: false, href: '/contact/' },
];
const legal = [
  { name: 'Cookie Policy', isExternal: false, href: '/cookie-policy/' },
  { name: 'Privacy Policy', isExternal: false, href: '/privacy-policy/' },
];
const social = [
  {
    name: 'GitHub',
    href: 'https://github.com/taichi-dev/taichi',
    isExternal: true,
    icon: (props: JSX.IntrinsicAttributes) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/TaichiGraphics',
    isExternal: true,
    icon: (props: JSX.IntrinsicAttributes) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23z" fill="currentColor" />
      </svg>
    ),
  },
];

/**
 * The layout component shared by different pages.
 */
export default function Layout({ children }: LayoutProps) {
  const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';
  return (
    <div className="flex flex-col h-full">
      <header className="sticky top-0 z-50">
        {/* Development mode banner */}
        <Banner
          shortText="This is a test environment!"
          text="This is a test environment and periodically may be unavailable and/or may contain outdated and incorrect information."
          cookieName="acceptTestingEnvironment"
          isVisible={activeEnv === 'development'}
          isDismissible
        />
        <NavBar
          githubURL="https://github.com/taichi-dev/taichi"
          logo={Logo}
          navbarItems={navbarItems}
        />
      </header>

      <main className="flex-grow">{children}</main>

      <CookieConsent
        disableStyles
        // debug={true}
        location="bottom"
        // This needs to agree with CookieConsent in gatsby-config file
        cookieName="taichi-google-analytics"
        buttonClasses="md:ml-6 bg-gray-50 px-2 py-1.5 text-sm border border-black font-medium text-gray-800 hover:bg-gray-200 focus:outline-none"
        contentClasses="text-sm text-gray-700 md:mt-2"
        containerClasses="bg-gray-100 p-4 align-baseline flex flex-wrap justify-between left-0 fixed w-full z-50 bg-opacity-90"
      >
        Taichi website uses cookies for security, improvement and analytics
        purposes. By using this site, you agree to these uses. Learn more
        {' '}
        <SafeLink
          to="/cookie-policy/"
          isExternal={false}
          className="font-bold hover:underline"
        >
          here
        </SafeLink>
        .
      </CookieConsent>

      <footer className="inset-x-0 bottom-0">
        <Footer
          products={products}
          resources={resources}
          company={company}
          legal={legal}
          social={social}
        />
      </footer>
    </div>
  );
}
